import React, {useCallback, useEffect, useRef, useState} from 'react';
import {DataFrameView, DataHoverEvent, GrafanaTheme2} from '@grafana/data';
import convex from '@turf/convex'
import turfCenter from '@turf/center'
import {useStyles2, useTheme2} from '@grafana/ui';
import {config, locationService, RefreshEvent} from '@grafana/runtime';
import {observer} from 'mobx-react-lite';
import DeckGL from '@deck.gl/react';
import MapLibre, {AttributionControl} from 'react-map-gl/maplibre';
import {LinesGeoJsonLayer} from '../deckLayers/LinesLayer/lines-geo-json-layer';
import {MyPathLayer} from '../deckLayers/PathLayer/path-layer';
import {IconClusterLayer} from '../deckLayers/IconClusterLayer/icon-cluster-layer';
import {AggrTypes, colTypes, DeckFeature, Feature, Vertices,
    ViewState, DeckLine} from '../store/interfaces';
import Menu from '../components/Menu';
import {
    getBounds,
    getFirstCoordinate,
    useRootStore,
    genParPathText,
    genParentLine,
    genLinksText,
    genExtendedPLine,
    mergeVertices, initBasemap, initMapView, toRGB4Array, findComments, hexToRgba, loadSvgIcons, parseObjFromString
} from '../utils';

import {Tooltip} from './Tooltips/Tooltip';
import {Point} from "geojson";
import {WebMercatorViewport} from "@deck.gl/core/typed";
import {geomapLayerRegistry} from "../layers/registry";
import {MapCenterID} from "../view";
import {LineTextLayer} from "../deckLayers/TextLayer/text-layer";
import {ScatterplotLayer, PolygonLayer} from '@deck.gl/layers';
import {MyPolygonsLayer} from "../deckLayers/PolygonsLayer/polygons-layer";
import {toJS} from "mobx";
import {MyGeoJsonLayer} from "../deckLayers/GeoJsonLayer/geojson-layer";
import {MyIconLayer} from "../deckLayers/IconLayer/icon-layer";
import {PositionTracker} from "./Geocoder/PositionTracker";
import {flushSync} from "react-dom";
import {
    DARK_AUTO_HIGHLIGHT, DARK_CENTER_PLOT, DARK_HULL_HIGHLIGHT,
    DEFAULT_CLUSTER_SCALE,
    DEFAULT_COMMENT_COLOR,
    DEFAULT_ICON_NAME2, LIGHT_AUTO_HIGHLIGHT, LIGHT_CENTER_PLOT, LIGHT_HULL_HIGHLIGHT,
    parDelimiter
} from "./defaults";
import {RGBAColor} from "@deck.gl/core/utils/color";
import {getThresholdForValue} from "../editor/Thresholds/data/threshold_processor";
import {getIconRuleForFeature} from "../editor/IconsSVG/data/rules_processor";
import {IconsGeoJsonLayer} from "../deckLayers/IconClusterLayer/icons-geo-json-layer";
import {PathStyleExtension} from "@deck.gl/extensions";
import {throttleTime} from "rxjs";
import {StateTime} from "./Geocoder/StateTime";

export let libreMapInstance, thresholds
const Mapgl = () => {
    const { pointStore, lineStore, viewStore, options, data, width, height, replaceVariables, eventBus  } = useRootStore();
    thresholds = options.globalThresholdsConfig
    const svgIconRules = options.svgIconsConfig
    const locLabelName = options.common?.locLabelName
    const theme2 = useTheme2()

    const {
        //<editor-fold desc="store imports">
        getPoints,
        getisOffset,
        getMode,
        setPoints,
        setSelCoord,
        getPolygons,
        setPolygons,
        setPath,
        getPath,
        setGeoJson,
        getComments,
        setAllComments,
        getGeoJson,
        getSelectedIp,
        getSelFeature,
        getSelIds,
        switchMap,
        getisShowSVG,
        getSelectedFeIndexes,
        setSelectedIp,setTooltipObject,
        getTooltipObject,
        getBlankInfo,
        getisShowPoints,
        setSvgIcons,
        getSvgIcons
        //</editor-fold>
    } = pointStore;
    const {
        getViewState,
        setViewState,
        getClusterMaxZoom,
    } = viewStore;

    const {getisShowLines, getEditableLines, getLineSwitchMap , getDirection, setDirection, setVertices} = lineStore;

    const deckRef = useRef(null);
    const mapRef: any = useRef(null);
    const [hoverInfo, setHoverInfo] = useState(getTooltipObject);
    const [closedHint, setClosedHint] = useState(false);
    const [source, setSource] = useState()
    const [isRenderNums, setIsRenderNums] = useState(true)
    const [isShowCenter, setShowCenter] = useState(getSelectedIp ? true : false)
    const [localViewState, setLocalViewState] = useState<ViewState | undefined>(getViewState);
    const [cPlotCoords, setCPlotCoords] = useState<ViewState | undefined>()
    // const [_, setLocation] = useState(locationService.getLocation())
    const [layers, setLayers] = useState<any>([])
    const [refresh, setRefresh] = useState<any>({r:5})

    const [hoverCluster,setHoverCluster] = useState<any>()
    const timeZone = replaceVariables('$__timezone')
    const [time, setTime] = useState<any>(data.timeRange.to.unix()*1000);
    const [hasAnnots, setHasAnnots] = useState(data?.annotations?.length)

    useEffect(() => {
        if (data.timeRange) {
            setTime(data.timeRange.to.unix()*1000)
            setHasAnnots(data.annotations?.length)
        }
    }, [data]);


    useEffect(() => {
        const subscriber = eventBus.getStream(RefreshEvent).subscribe(event => {
            //console.log(`Received event: ${event.type}`);
            setRefresh(prev=> ({...prev}))
        })

        return () => {
            subscriber.unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventBus]);

    const expandTooltip = (info, event) => {
        const position = info.coordinate
        if (position) {
            const [longitude, latitude,] = position.map(e => parseFloat(e.toFixed(6)))

            setSelCoord(
                {
                    coordinates: [longitude, latitude],
                    type: "Point"
                })
        }

        if (info.picked) {
            const properties = info.object?.properties || info.object // cluster/icon datasets
            const {id} = info.object
            const {locName: ip, parPath} = properties

            if (ip) {
                const {aggrType, colType} = properties
                setClosedHint(false);
                const layerId = info.sourceLayer?.id
                const lineId = layerId?.startsWith('edit-lines') ? id : null

                const ipDelimited = ip?.split(parDelimiter)[0]
                const geom = switchMap?.get(ipDelimited)?.geometry as Point

                const OSM = libreMapInstance?.getZoom()
                if (geom && getMode !== 'modify')
                {
                    const [longitude, latitude] = geom?.coordinates
                    setShowCenter(true)
                    setCPlotCoords(
                        {
                            longitude,
                            latitude,
                            zoom: OSM ? OSM : 18,
                            maxPitch: 45 * 0.95,
                            bearing: 0,
                            pitch: 0
                        }
                    )
                }

                const isAggr = AggrTypes.includes(aggrType)
                setIsRenderNums(!isAggr)
                setTooltipObject(info); // this pins tooltip
                setSelectedIp(ip, lineId ? [lineId] : null)


            } else if (info.objects?.length) {
                // zoom on cluster click
                const featureCollection = {
                    type: 'FeatureCollection',
                    features: info.objects,
                };
                // @ts-ignore
                const center = turfCenter(featureCollection)
                    const [longitude, latitude] = center.geometry.coordinates;
                    const expansionZoom = info.expZoom
                const newState = {
                    longitude,
                    latitude,
                    zoom: expansionZoom,
                    transitionDuration: 250,
                    maxPitch: 45 * 0.95,
                    rnd: Math.random()   /// to trigger zoom in/out on repeat click the same cluster
                }
                        setLocalViewState(newState as ViewState);
            }
        } else {
            // reset tooltip by clicking blank space
            setHoverCluster(null)
            setHoverInfo({})
            setShowCenter(true)
            setSelectedIp('');
            setClosedHint(true);
            setTooltipObject({});

        }
    };

    const loadPoints = async (data) => {


        const isDir = ['target', 'source'].includes(replaceVariables('$locRole'))
        const direction = isDir ? replaceVariables(`$locRole`) : getDirection
        const startIds = {};
        for (const key in colTypes) {
            if (Object.prototype.hasOwnProperty.call(colTypes, key)) {
                startIds[colTypes[key]] = 0;
            }
        }

        const transformed: any = [];
let svgIcons
        if (svgIconRules?.length) {
            svgIcons = await loadSvgIcons(svgIconRules)
        }

        if (svgIcons && Object.keys(svgIcons).length) {
            setSvgIcons(svgIcons)
        }


        if (options?.dataLayers?.length > 0) {
            const vertices: Vertices = {}
            for (let i = 0; i < options.dataLayers.length; i++) {
                const dataLayer = options.dataLayers[i];
                const layer = geomapLayerRegistry.getIfExists(dataLayer.type);

                const extOptions = {
                    ...dataLayer,
                    config: {
                        ...dataLayer.config,
                        globalThresholdsConfig: thresholds,
                        startId: startIds[dataLayer.type] ?? 0,
                        vertices,
                        direction
                    },
                };

                const pointsUpRes = layer?.pointsUp ? await layer.pointsUp(data, extOptions) : null
                const features = Array.isArray(pointsUpRes) ? pointsUpRes : []
                const res = {
                    colType: dataLayer.type,
                    features,
                    vertices:  Object.keys(vertices).length ? vertices : null
                };

                startIds[res.colType] = startIds[res.colType] + res.features.length;
                transformed.push(res);
            }
        }

        if (!localViewState) {
            const view = initMapView(options.view)

            let longitude, latitude, zoom;
            if (view.id === MapCenterID.Auto) {
                if (transformed?.length > 0) {
                    const viewport = new WebMercatorViewport({width, height});
                    const allCoordinates = transformed.reduce((acc,curr)=> acc.concat(curr.features), [])
                    const boundsCoords = allCoordinates.map((el: any)=> {

                        const firstCoord = getFirstCoordinate(el.geometry)
                        return (
                            {
                                type: 'Feature', geometry: {
                                    type: 'Point',
                                    coordinates: firstCoord
                                }
                            }
                        )
                    }).filter(el=>el)

                    if (boundsCoords.length > 1) {
                        ({longitude, latitude, zoom} = view)

                        const [minLng, minLat, maxLng, maxLat] = getBounds(boundsCoords);
                        const bounds: [[number, number], [number, number]] = [[minLng, minLat], [maxLng, maxLat]];

                        if (minLng && minLat && maxLng && maxLat) {
                            ({longitude, latitude, zoom} = viewport.fitBounds(bounds));
                        }
                    }
                    // if no query points in auto mode
                    if (!longitude) {
                        ({longitude, latitude, zoom} = view)
                    }
                }
            } else {
                // console.log('not auto');
                ({longitude, latitude, zoom} = view)
            }

            const deckInitViewState = {
                longitude,
                latitude,
                zoom,
                maxPitch: 45 * 0.95 // for non-wgs projection
            };

                setViewState({...deckInitViewState})


            initBasemap(options.basemap, setSource, false, theme2)

        }
        let markers: Feature[] = []
        let vertices:  Vertices = {}
        let polygons: Feature[] = []
        let path: Feature[] = []
        let geojson: Feature[] = []
        const alerts = {}

        if (data.annotations?.length && data.annotations[0].fields.length) {
            data.annotations.forEach(a=> {
                    const annotations = new DataFrameView(a).toArray()

                    annotations.forEach(b=> {
                        if (b?.text) {
                            b.labels = parseObjFromString(b?.text)
                        }
                        const name = b.labels?.alertname
                        const locName = b.labels?.[locLabelName]
                        if (name) {
                            const alertMap = alerts[name]
                            if (alertMap) {
                                const alertAnnots = alertMap.get(locName)
                                if  (alertAnnots) {
                                    alertAnnots.push(b)
                                } else {
                                    alertMap.set(locName, [b])
                                }
                            }
                            else {
                                const newAlertMap = new Map()
                                newAlertMap.set(b.labels[locLabelName], [b])
                                alerts[name] = newAlertMap
                            }
                        }
                    })
                }
            )

        }

        transformed.forEach((el: any)=> {
            switch (el.colType){
                case colTypes.Points:
                    if (el?.features.length) {
                        vertices = mergeVertices(vertices, el?.vertices)
                        markers = markers.concat(el?.features)

                        markers = markers.map(f=> {
                            const {locName} = f.properties
                            const geom = f.geometry as Point;

                            const metric = f.properties?.metric
                            const threshold = getThresholdForValue(f.properties, metric, thresholds)
                            const rulesThreshold = getIconRuleForFeature(f.properties, svgIconRules)

                            const status: any = {threshold: {...threshold, ...rulesThreshold}}
                            let annotations: any = []

                            Object.keys(alerts).forEach(name=> {

                                const alertAnnots = alerts[name].get(locName)
                                if (alertAnnots?.length) {
                                    annotations.push(alertAnnots)
                                }}
                            )

                            if (annotations.length) {
                                status.all_annots = annotations
                            }

                            const sources = vertices[locName]?.sources ? {...vertices[locName]?.sources} : undefined
                            return {...f,
                                geometry: {type: "Point", coordinates: geom.coordinates},
                                properties: {...f.properties, ...(status && Object.entries(status).reduce((acc, [key, value]) => (value !== undefined ? { ...acc, [key]: value } : acc), {})), sources: sources && Object.keys(sources).length >0 ? sources : undefined}}
                        })

                    }
                    break;
                case colTypes.Polygons:
                    if (el?.features.length) {
                        polygons = polygons.concat(el?.features)
                    }
                    break;
                case colTypes.Path:
                    if (el?.features.length) {
                        path = path.concat(el?.features)
                    }
                    break;
                case colTypes.GeoJson:
                    if (el?.features.length) {
                        geojson = geojson.concat(el?.features)
                    }
                    break;
            }
        })

        setVertices(vertices)

        const commentsData: any = []
        const comments = findComments(vertices)

        let counter = 0
        comments?.forEach((comment) => {
            const { text, iconColor, orderId, coords,tar, src} = comment;
            if (tar && text && orderId && coords) {

                const hexColor = iconColor && theme2.visualization.getColorByName(iconColor)
                commentsData.push({
                    type: "Feature",
                    id: counter,
                    geometry: {
                        type: 'Point',
                        coordinates: coords
                    },
                    properties: {
                        note: text,
                        tIdx: orderId+1,
                        tar,
                        iconColor: iconColor?.indexOf('rgb') > -1 ? iconColor : (iconColor && hexToRgba(hexColor)) ?? DEFAULT_COMMENT_COLOR,
                        isShowTooltip: true,
                        displayProps: ['note', 'tar', 'tIdx']
                    }
                })
                counter++
            }

        })
        commentsData && setAllComments(commentsData)
            markers && setPoints(markers)
            polygons && setPolygons(polygons)
            path && setPath(path)
            geojson && setGeoJson(geojson)
    }

    useEffect(() => {

        if (data && data.series.length) {
            loadPoints(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh, getDirection, data, width, height, options]);  // _


    const onMapLoad = useCallback(()=> {

        const myRef: {
            current: {getMap: Function} | null
        } = mapRef
        libreMapInstance = myRef.current?.getMap ? myRef.current.getMap() : null;
    } , [])
    const layerProps = {
        pickable: true,
        autoHighlight: true,
        highlightColor: toRGB4Array(theme2.isDark ? DARK_AUTO_HIGHLIGHT : LIGHT_AUTO_HIGHLIGHT ) ,
        onHover: setHoverInfo,
        setShowCenter,
        getEditableLines,
        getSelectedFeIndexes,
        getisShowSVG,
        getPoints,
        getSelFeature,
        switchMap,
        getMode,
        getSelectedIp,
        setClosedHint,
        setSelectedIp,
        theme2,
        time
    };

    const lineLayersProps = {
        getDirection,
        getisOffset,
        getComments,
        setTooltipObject,
        setCPlotCoords,
    };

    const iconLayersProps = {
        getSvgIcons,
        getisShowPoints,
        setHoverInfo,
        hoverCluster,
        setHoverCluster
    }

    const getLayers = () => {
        let lines, icons, pathLine, pathLineExt, list1, nums, commentsLayer, clusterLayer
        const secLayers: any = []
        let newLayers: any = [];
        const iconLayers: any = []
        const lineLayers: any = []
        const clusters: any = []
        const markers: any = getPoints;
        const polygons: any = getPolygons;
        const path: any = getPath;
        const geojson: any = getGeoJson;

        const allFeatures = [markers, polygons,path, geojson].filter(el=> el)
        if (allFeatures?.length < 1) {
            setLayers(newLayers);
            return
        }

        if (polygons.length>0) {
            secLayers.push(MyPolygonsLayer({ ...layerProps,data: polygons }));
        }

        if (path.length>0) {
                secLayers.push(MyPathLayer({ ...layerProps, data: path, type: 'path' }));

        }
        if (geojson.length>0) {

            const featCollection = {
                type: 'FeatureCollection',
                features: geojson
            }
            secLayers.push(MyGeoJsonLayer({ ...layerProps, data: featCollection }));

        }


        if (markers.length>0 || secLayers.length>0) {

            if (hoverCluster?.objects?.length > 2 || hoverInfo.prevHullData) {


                const features = hoverCluster?.objects ?? hoverInfo.prevHullData
                const featureCollection = {
                    type: 'FeatureCollection',
                    features,
                };
                // @ts-ignore
                const data = convex(featureCollection)
                if (!data) {return }

                const convexLayer = new PolygonLayer({
                    id: 'convex-hull',
                    data: [
                        {polygon: data.geometry.coordinates},
                    ],
                    onHover: (o: any)=> {
                        if (getTooltipObject?.object && Object.keys(getTooltipObject?.object).length) {
                            return}
                        if (!o.object) {setHoverInfo({}); return}

                        if (hoverCluster?.object) {
                            const { cluster, colorCounts, annotStateCounts } = hoverCluster.object;
                            o.object = { ...o.object, cluster, colorCounts, annotStateCounts };
                        }

                        flushSync(()=>{setHoverInfo({...o,
                            prevHullData: features })
                            closedHint && setClosedHint(false);})

                    },
                    onClick: ()=> {
                        setHoverInfo({})
                        setHoverCluster(null)
                        setTooltipObject({});
                    },
                    getPolygon: (d: any) => d.polygon,
                    filled: true,
                    stroked: false,
                    lineWidthMaxPixels: 1,
                    getLineWidth: 1,
                    getLineColor: [42, 89, 191],
                    getFillColor: toRGB4Array(theme2.isDark ? DARK_HULL_HIGHLIGHT :LIGHT_HULL_HIGHLIGHT), //[70, 115, 219, 70],
                    pickable: true,
                    extruded: false,
                });
                iconLayers.push(convexLayer)
            }

            const lineSwitchMap = getLineSwitchMap
            if (getSelFeature?.properties.colType === colTypes.Points) {
                /// Path to tar/src

                const selPathPts = getSelIds.map((id)=> getEditableLines[id]).filter(el=>el)

                const pathLinesCoords = selPathPts.length > 0 && genParentLine({features:selPathPts, switchMap, lineSwitchMap, getisOffset, time})[1]

                pathLine = pathLinesCoords && pathLinesCoords?.length > 0 ? MyPathLayer({
                    ...layerProps,
                    data: pathLinesCoords,
                    type: 'par-path-line',
                }) : null

                const pathExtCoords = pathLinesCoords && genExtendedPLine({features: selPathPts, switchMap, lineSwitchMap, getisOffset, time})

                pathLineExt = pathExtCoords && pathExtCoords?.length > 0 ?
                    MyPathLayer({
                        ...layerProps,
                        data: pathExtCoords,
                        type: 'par-path-extension'
                    }) : null

                const numsData = isRenderNums && getisOffset && genParPathText(selPathPts)
                nums = numsData?.length > 0 ? LineTextLayer({data: numsData, type: 'nums', dir: 'to'}) : null

            }
            const linksText = getEditableLines.map(f=> genLinksText(f, switchMap))
            //const isAggregator =  AggrTypes.includes(getSelFeature?.properties.aggrType ?? '')

            list1 = !getisOffset && linksText?.length > 0 ? LineTextLayer({
                data: linksText.reduce((acc: any,curr: any)=> curr.to ? acc.concat(curr.to) : acc,[]),
                dir: 'to',
                type: 'list1'
            }) : null

                const lFeatures = getEditableLines

            /// Edges render
            if (getisShowLines && lFeatures?.length > 0) {
                const linesCollection = {
                    type: 'FeatureCollection',
                    features: lFeatures
                };


                lines = LinesGeoJsonLayer({
                    ...layerProps,
                    ...lineLayersProps,
                    linesCollection
                })
                lineLayers.push(lines)
            }

            let clusterLayerData;

            if (getisShowPoints) {
                clusterLayerData = markers
                    .map((el): DeckFeature | undefined => {
                        if (el) {
                            const pointGeometry = el.geometry as Point;
                            return {
                                id: el.id,
                                coordinates: pointGeometry.coordinates,
                                properties: el.properties,
                            };
                        }
                        return undefined;
                    })
                    .filter((val): val is DeckFeature => val !== undefined);
            }
            let iconLayerData = markers;

            if (iconLayerData?.length) {
                const featureCollection = {
                    type: 'FeatureCollection',
                    features: iconLayerData,
                };

                icons = IconsGeoJsonLayer({
                    ...layerProps,
                    ...iconLayersProps,
                    featureCollection,
                })
                iconLayers.push(icons)
            }

            if (clusterLayerData?.length) {
                clusters.push(clusterLayerData)
            }

            if (clusters.length) {
                clusterLayer = new IconClusterLayer({
                        ...layerProps,
                        ...iconLayersProps,
                        getPosition: (d) => d.coordinates,
                        data: clusters.reduce((acc,curr)=> acc.concat(curr), []),
                        id: 'icon-cluster',
                    sizeScale: DEFAULT_CLUSTER_SCALE,
                    maxZoom: getClusterMaxZoom,
                        //onClick: (info, event) => {
                        //setHoverInfo(getBlankInfo);
                        //},
                        thresholds: []
                    }
                );
            }
            newLayers = [...secLayers, ...lineLayers, ...iconLayers]
            if (list1) {
                newLayers.push(list1)
            }
            if (pathLine) {
                newLayers.unshift(pathLine)
                newLayers.push(pathLineExt)
                newLayers.push(nums)
            }

            if (getComments && getComments?.length > 0 ) {  /// comments for all collections
                commentsLayer = MyIconLayer({
                    ...layerProps,
                    ...iconLayersProps,
                    data: getComments,
                    getSelectedFeIndexes,
                    setClosedHint,
                    setSelectedIp,
                })
                newLayers.push(commentsLayer)
            }

            let centerPlot
            if (isShowCenter && cPlotCoords && getSelFeature ) {
                const {longitude, latitude} = cPlotCoords
                centerPlot = new ScatterplotLayer({
                    id: 'centerplot-layer',
                    data: [{coordinates: [longitude, latitude]}],
                    pickable: false,
                    opacity: 0.2,
                    stroked: false,
                    filled: true,
                    radiusScale: 5,
                    radiusMinPixels: 1,
                    radiusMaxPixels: 18,
                    lineWidthMinPixels: 1,
                    getPosition: (d: any) => d.coordinates,
                    getRadius: (d: any )=> Math.sqrt(d.exits),
                    getFillColor: (d: any) => toRGB4Array(theme2.isDark ? DARK_CENTER_PLOT : LIGHT_CENTER_PLOT),
                });
                newLayers.push(centerPlot)
            }

            newLayers.push(clusterLayer) // in that order for better clusters visibilty

        }

        setLayers(newLayers.filter(el => el !== null && el !== undefined))
    };

    // Unused for now: not changing dashboard variables from inside the plugin.

    // useEffect(() => {
    //     const history = locationService.getHistory()
    //     const unlistenHistory = history.listen((location: any) => {
    //         setLocation(location)
    //     })
    //     return unlistenHistory
    //
    // }  ,[])

    useEffect(()=> {
        if (!getViewState) {return}
            setLocalViewState(getViewState)
            setCPlotCoords(getViewState)
    }, [getViewState])

    useEffect(() => {
        /// get some proves that data was specified at least somewhere and needs some time to load.
        if (!options.dataLayers?.some(el=> el?.query) && !data.request?.targets?.some(el=> el?.queryType === 'snapshot')
        && !data.series?.some(el=>el?.meta?.transformations?.length)
        ) {return}
        if (!getPoints.length && !getPolygons.length && !getPath.length && !getGeoJson.length && data?.series?.length){
            setRefresh(prev=> ({...prev}))
            return}
        //setShowCenter(false)
        getLayers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        getTooltipObject,
        hoverCluster,
        getClusterMaxZoom,
        getSelIds,
        getViewState,
        cPlotCoords,
        getMode,
        getPolygons,
        getPoints,
        getPath,
        getGeoJson,
        getSelectedIp,
        getisOffset,
        getisShowSVG,
        getisShowLines,
        getisShowPoints,
    ]);

    return (
            <>
                    {source && localViewState && <> <DeckGL
                        ref={deckRef}
                        style={{
                            pointerEvents: 'all',
                            inset: 0,
                            // zIndex: 1
                        }}
                        layers={layers}
                        initialViewState={localViewState}
                        controller={{
                            dragMode: 'pan',
                            doubleClickZoom: false,
                            scrollZoom: {smooth: false, speed: 0.005},
                            inertia: true
                        }
                        }
                        onClick={(info, event) => expandTooltip(info, event)}
                    >
                        <MapLibre
                            onLoad={onMapLoad}
                            ref={mapRef}
                            mapStyle={source}
                            attributionControl={false}>
                            <AttributionControl style={{ position: 'absolute', bottom: 0, left: 0 }} />
                        </MapLibre>

                    </DeckGL>
                         <PositionTracker/>
                        {hasAnnots && <StateTime time={time}/>}
                        <Tooltip time={time} timeZone={timeZone} position={0} info={hoverInfo} isClosed={closedHint} setTooltipObject={setTooltipObject} setClosedHint={setClosedHint}
                        />

    {switchMap && <Menu
        time={time}
        timeZone={timeZone}
        data={data}
        setShowCenter={setShowCenter}
    />}

                   </>
                    }

          </>
    );
}

export default observer(Mapgl);

